/* latin-ext */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ITw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqhPAMif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqZPAA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ISQ.woff) format("woff");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqZPBg.woff) format("woff");
}
.sv-dragdrop-movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.sv-dragdrop-moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
sv-popup {
  display: block;
  position: absolute;
  z-index: -1;
}

.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  outline: none;
}

.sv-popup__container {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
  position: absolute;
  padding: 0;
}

.sv-popup__shadow {
  width: 100%;
  height: 100%;
}

.sv-popup__body-content {
  background-color: var(--background, #fff);
  border-radius: calc(0.5 * var(--base-unit, 8px));
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 90vw;
}

.sv-popup--modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sv-popup--modal .sv-popup__container {
  position: static;
}
.sv-popup--modal .sv-popup__body-content {
  padding: calc(4 * var(--base-unit, 8px));
}

.sv-popup--overlay {
  width: 100%;
}
.sv-popup--overlay .sv-popup__container {
  background: rgba(144, 144, 144, 0.5);
  max-width: 100vw;
  max-height: calc(100vh - 1 * var(--base-unit, 8px));
  height: calc(100vh - 1 * var(--base-unit, 8px));
  width: 100%;
  padding-top: calc(2 * var(--base-unit, 8px));
  border: unset;
}
.sv-popup--overlay .sv-popup__body-content {
  max-height: 100vh;
  max-width: 100vw;
  border-radius: calc(2 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) 0px 0px;
  background: var(--background, #fff);
  box-shadow: 0px calc(1 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) rgba(0, 0, 0, 0.1);
  padding: calc(3 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
  height: calc(100% - (1 * var(--base-unit, 8px)));
}
.sv-popup--overlay .sv-popup__scrolling-content {
  height: calc(100% - 10 * var(--base-unit, 8px));
}
.sv-popup--overlay .sv-popup__body-footer {
  margin-top: calc(2 * var(--base-unit, 8px));
}
.sv-popup--overlay .sv-popup__body-footer-item {
  width: 100%;
}
.sv-popup--overlay .sv-popup__button--cancel {
  background-color: var(--primary, #19b394);
  border: 2px solid var(--primary, #19b394);
  color: var(--primary-foreground, #fff);
}

.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;
}
.sv-popup__scrolling-content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: var(--background-dim, #f3f3f3);
}
.sv-popup__scrolling-content::-webkit-scrollbar-thumb {
  background: var(--primary-light, rgba(25, 179, 148, 0.1));
}

.sv-popup__content {
  min-width: 100%;
}

.sv-popup--show-pointer.sv-popup--top .sv-popup__pointer {
  transform: translate(calc(-1 * var(--base-unit, 8px))) rotate(180deg);
}

.sv-popup--show-pointer.sv-popup--bottom .sv-popup__pointer {
  transform: translate(calc(-1 * var(--base-unit, 8px)), calc(-1 * var(--base-unit, 8px)));
}

.sv-popup--show-pointer.sv-popup--right {
  transform: translate(calc(1 * var(--base-unit, 8px)));
}
.sv-popup--show-pointer.sv-popup--right .sv-popup__pointer {
  transform: translate(-12px, -4px) rotate(-90deg);
}

.sv-popup--show-pointer.sv-popup--left {
  transform: translate(calc(-1 * var(--base-unit, 8px)));
}
.sv-popup--show-pointer.sv-popup--left .sv-popup__pointer {
  transform: translate(-4px, -4px) rotate(90deg);
}

.sv-popup__pointer {
  display: block;
  position: absolute;
}
.sv-popup__pointer:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-left: calc(1 * var(--base-unit, 8px)) solid transparent;
  border-right: calc(1 * var(--base-unit, 8px)) solid transparent;
  border-bottom: calc(1 * var(--base-unit, 8px)) solid var(--background, #fff);
  align-self: center;
}

.sv-popup__body-header {
  font-family: Open Sans;
  font-size: calc(3 * var(--base-unit, 8px));
  line-height: calc(4 * var(--base-unit, 8px));
  font-style: normal;
  font-weight: 700;
  margin-bottom: calc(2 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
}

.sv-popup__body-footer {
  display: flex;
  margin-top: calc(4 * var(--base-unit, 8px));
}

.sv-popup__body-footer-item:first-child {
  margin-left: auto;
}

.sv-popup__body-footer-item + .sv-popup__body-footer-item {
  margin-left: calc(1 * var(--base-unit, 8px));
}

.sv-popup__button {
  padding: calc(2 * var(--base-unit, 8px)) calc(6 * var(--base-unit, 8px));
  background: var(--background, #fff);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  text-align: center;
  color: var(--primary, #19b394);
  border: none;
  outline: none;
}
.sv-popup__button:hover {
  box-shadow: 0 0 0 2px var(--primary, #19b394);
}

.sv-popup__button:disabled {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.16));
  cursor: default;
}
.sv-popup__button:disabled:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.sv-popup__button--apply {
  background-color: var(--primary, #19b394);
  color: var(--primary-foreground, #fff);
}

.sv-popup__button--apply:disabled {
  background-color: var(--background-dim, #f3f3f3);
}

.sv-container-modern {
  color: #404040;
  font-size: 16px;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv-container-modern__title {
  padding-left: 0.55em;
  color: #1ab394;
  padding-top: 5em;
  padding-bottom: 0.9375em;
}
@media only screen and (min-width: 1000px) {
  .sv-container-modern__title {
    margin-right: 5%;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1000px) {
  .sv-container-modern__title {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.sv-container-modern__title h3 {
  margin: 0;
  font-size: 1.875em;
}

.sv-container-modern__title h5 {
  margin: 0;
}

.sv-container-modern__close {
  clear: right;
}

.sv-container-modern fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.sv-container-modern legend {
  border: none;
  padding: 0;
  margin: 0;
}

.sv-body__timer,
.sv-body__page,
.sv-body__footer.sv-footer.sv-action-bar {
  margin-top: 2em;
}
@media only screen and (min-width: 1000px) {
  .sv-body__timer,
.sv-body__page,
.sv-body__footer.sv-footer.sv-action-bar {
    margin-right: 5%;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1000px) {
  .sv-body__timer,
.sv-body__page,
.sv-body__footer.sv-footer.sv-action-bar {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.sv-body__timer {
  padding: 0 calc(1 * var(--base-unit, 8px));
  box-sizing: border-box;
}

.sv-body__progress {
  margin-bottom: 4.5em;
}

.sv-body__progress:not(:first-child) {
  margin-top: 2.5em;
}

.sv-page__title {
  margin: 0;
  margin-bottom: 1.333em;
  font-size: 1.875em;
  padding-left: 0.293em;
}

.sv-page__description {
  min-height: 2.8em;
  font-size: 1em;
  padding-left: 0.55em;
}

.sv-page__title + .sv-page__description {
  margin-top: -2.8em;
}

.sv-panel {
  box-sizing: border-box;
  width: 100%;
}

.sv-panel__title {
  font-size: 1.25em;
  margin: 0;
  padding: 0;
  padding-bottom: 0.1em;
  padding-left: 0.44em;
  padding-right: 0.44em;
  position: relative;
}

.sv-panel__footer {
  margin: 0;
  padding: 1em 0.44em 1em 0;
}

.sv-panel__description {
  padding-left: 0.55em;
}

.sv-panel__title--expandable {
  cursor: pointer;
  display: flex;
  padding-right: 24px;
  align-items: center;
}

.sv-panel__title--expandable:after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 12px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}

.sv-panel__title--expandable.sv-panel__title--expanded:after {
  transform: rotate(180deg);
}

.sv-panel__icon {
  outline: none;
}

.sv-panel__icon:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 0.5em;
  width: 0.6em;
  margin-left: 1.5em;
  vertical-align: middle;
}

.sv-panel__icon--expanded:before {
  transform: rotate(180deg);
}

.sv-panel .sv-question__title {
  font-size: 1em;
  padding-left: 0.55em;
}

.sv-panel__content:not(:first-child) {
  margin-top: 0.75em;
}

.sv-panel .sv-row:not(:last-child) {
  padding-bottom: 1.875em;
}

.sv-panel__title--error {
  background-color: rgba(213, 41, 1, 0.2);
}

.sv-paneldynamic__progress-container {
  position: relative;
  margin-left: 0.75em;
  margin-right: 250px;
  margin-top: 20px;
}

.sv-paneldynamic__add-btn {
  background-color: #1948b3;
  float: right;
  margin-top: -18px;
}

[dir=rtl] .sv-paneldynamic__add-btn,
[style*="direction:rtl"] .sv-paneldynamic__add-btn {
  float: left;
}

.sv-paneldynamic__add-btn--list-mode {
  float: none;
  margin-top: 1em;
}

.sv-paneldynamic__remove-btn {
  background-color: #ff1800;
  margin-top: 1.25em;
}

.sv-paneldynamic__remove-btn--right {
  margin-top: 0;
  margin-left: 1.25em;
}

.sv-paneldynamic__prev-btn,
.sv-paneldynamic__next-btn {
  box-sizing: border-box;
  display: inline-block;
  fill: #404040;
  cursor: pointer;
  width: 0.7em;
  top: -0.28em;
  position: absolute;
}

.sv-paneldynamic__prev-btn svg,
.sv-paneldynamic__next-btn svg {
  display: block;
  height: 0.7em;
  width: 0.7em;
}

.sv-paneldynamic__prev-btn {
  left: -1.3em;
  transform: rotate(90deg);
}

.sv-paneldynamic__next-btn {
  right: -1.3em;
  transform: rotate(270deg);
}

.sv-paneldynamic__prev-btn--disabled,
.sv-paneldynamic__next-btn--disabled {
  fill: #dbdbdb;
  cursor: auto;
}

.sv-paneldynamic__progress-text {
  color: #9d9d9d;
  font-weight: bold;
  font-size: 0.87em;
  margin-top: 0.69em;
  margin-left: 1em;
}

.sv-paneldynamic__separator {
  border: none;
  margin: 0;
}

.sv-paneldynamic__progress--top {
  margin-bottom: 1em;
}

.sv-paneldynamic__progress--bottom {
  margin-top: 1em;
}

.sv-paneldynamic__panel-wrapper ~ .sv-paneldynamic__panel-wrapper {
  padding-top: 2.5em;
}

.sv-paneldynamic__panel-wrapper--in-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@supports (display: flex) {
  .sv-row {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sv-row > .sv-row__panel,
.sv-row__question:not(:last-child) {
    float: left;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 600px), only screen and (-ms-high-contrast: none) and (max-width: 600px) {
  .sv-row > .sv-row__panel,
.sv-row__question:not(:last-child) {
    padding-bottom: 2.5em;
    float: none;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  [dir=rtl] .sv-row__question:not(:last-child),
[style*="direction:rtl"] .sv-row__question:not(:last-child) {
    float: right;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 6000px), only screen and (-ms-high-contrast: none) and (max-width: 6000px) {
  .sv-row__question--small:only-child {
    max-width: 3000px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 3000px), only screen and (-ms-high-contrast: none) and (max-width: 3000px) {
  .sv-row__question--small:only-child {
    max-width: 1200px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 2000px), only screen and (-ms-high-contrast: none) and (max-width: 2000px) {
  .sv-row__question--small:only-child {
    max-width: 700px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1000px), only screen and (-ms-high-contrast: none) and (max-width: 1000px) {
  .sv-row__question--small:only-child {
    max-width: 500px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 500px), only screen and (-ms-high-contrast: none) and (max-width: 500px) {
  .sv-row__question--small:only-child {
    max-width: 300px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 600px), only screen and (-ms-high-contrast: none) and (max-width: 600px) {
  .sv-row > .sv-row__panel,
.sv-row__question {
    width: 100% !important;
    padding-right: 0 !important;
  }
}
.sv-row > .sv-row__panel,
.sv-row__question {
  vertical-align: top;
  white-space: normal;
}

.sv-row__question:first-child:last-child {
  flex: none !important;
}

.sv-row:not(:last-child) {
  padding-bottom: 2.5em;
}

.sv-question {
  overflow: auto;
  box-sizing: border-box;
  font-family: inherit;
}

.sv-question__title {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0.25em 0.44em;
  cursor: default;
  font-size: 1.25em;
  word-break: break-word;
}

.sv-question__required-text {
  line-height: 0.8em;
  font-size: 1.4em;
}

.sv-question__description {
  margin: 0;
  padding-left: 0.55em;
  font-size: 1em;
  word-break: break-word;
}

.sv-question__input {
  width: 100%;
  height: 1.81em;
}

.sv-question__content {
  margin-left: 0.55em;
}

.sv-question__erbox {
  color: #d52901;
  font-size: 0.74em;
  font-weight: bold;
}

.sv-question__erbox--location--top {
  margin-bottom: 0.4375em;
}

.sv-question__erbox--location--bottom {
  margin-top: 0.4375em;
}

.sv-question__footer {
  padding: 0.87em 0;
}

.sv-question__title--answer {
  background-color: rgba(26, 179, 148, 0.2);
}

.sv-question__title--error {
  background-color: rgba(213, 41, 1, 0.2);
}

.sv-question__header--location--top {
  margin-bottom: 0.65em;
}

.sv-question__header--location--left {
  float: left;
  width: 27%;
  margin-right: 0.875em;
}

[dir=rtl] .sv-question__header--location--left,
[style*="direction:rtl"] .sv-question__header--location--left {
  float: right;
}

.sv-question__header--location--bottom {
  margin-top: 0.8em;
}

.sv-question__content--left {
  overflow: hidden;
}

.sv-question__other {
  margin-top: 0.5em;
}

.sv-question__form-group {
  margin-top: 0.5em;
}

.sv-question--disabled .sv-question__header {
  color: rgba(64, 64, 64, 0.5);
}

.sv-image {
  display: inline-block;
}

.sv-question__title--expandable {
  cursor: pointer;
  display: flex;
  padding-right: 24px;
  align-items: center;
}

.sv-question__title--expandable:after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 12px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}

.sv-question__title--expandable.sv-question__title--expanded:after {
  transform: rotate(180deg);
}

.sv-question__icon {
  outline: none;
}

.sv-question__icon:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 0.5em;
  width: 0.6em;
  margin-left: 1.5em;
  vertical-align: middle;
}

.sv-question__icon--expanded:before {
  transform: rotate(180deg);
}

.sv-progress {
  height: 0.19em;
  background-color: #e7e7e7;
  position: relative;
}

.sv-progress__bar {
  position: relative;
  height: 100%;
  background-color: #1ab394;
}

.sv-progress__text {
  position: absolute;
  margin-top: 0.69em;
  color: #9d9d9d;
  font-size: 0.87em;
  font-weight: bold;
  padding-left: 0.6321em;
}
@media only screen and (min-width: 1000px) {
  .sv-progress__text {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1000px) {
  .sv-progress__text {
    margin-left: 10px;
  }
}

.sv-title {
  font-family: "Raleway";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sv-description {
  color: rgba(64, 64, 64, 0.5);
}

.sv-question .sv-selectbase {
  margin-bottom: 4px;
}

.sv-selectbase__item {
  margin-bottom: 0.425em;
  vertical-align: top;
}

.sv-selectbase__item--inline {
  display: inline-block;
  padding-right: 5%;
}

.sv-selectbase__column {
  min-width: 140px;
  vertical-align: top;
}

.sv-selectbase__label {
  position: relative;
  display: block;
  box-sizing: border-box;
  cursor: inherit;
  margin-left: 41px;
  min-height: 30px;
}

[dir=rtl] .sv-selectbase__label,
[style*="direction:rtl"] .sv-selectbase__label {
  margin-right: 41px;
  margin-left: 0;
}

.sv-selectbase__decorator.sv-item__decorator {
  position: absolute;
  left: -41px;
}

[dir=rtl] .sv-selectbase__decorator.sv-item__decorator,
[style*="direction:rtl"] .sv-selectbase__decorator.sv-item__decorator {
  left: initial;
  right: -41px;
}

.sv-selectbase__clear-btn {
  margin-top: 0.9em;
  background-color: #1948b3;
}

.sv-selectbase .sv-selectbase__item.sv-q-col-1 {
  padding-right: 0;
}

.sv-multipletext {
  width: 100%;
  table-layout: fixed;
}

.sv-multipletext__item-label {
  display: flex;
  align-items: center;
}

.sv-multipletext__item {
  flex: 1;
}

.sv-multipletext__item-title {
  margin-right: 1em;
  width: 33%;
}

.sv-multipletext__cell:not(:first-child) {
  padding-left: 0.5em;
}

.sv-multipletext__cell:not(:last-child) {
  padding-right: 0.5em;
}

.sv-matrix {
  overflow-x: auto;
}
.sv-matrix .sv-table__cell--header {
  text-align: center;
}

.sv-matrix__label {
  display: inline-block;
  margin: 0;
}

.sv-matrix__cell {
  min-width: 10em;
  text-align: center;
}
.sv-matrix__cell:first-child {
  text-align: left;
}

.sv-matrix__text {
  cursor: pointer;
}

.sv-matrix__text--checked {
  color: #ffffff;
  background-color: #1ab394;
}

.sv-matrix__text--disabled {
  cursor: default;
}
.sv-matrix__text--disabled.sv-matrix__text--checked {
  background-color: #dbdbdb;
}

.sv-matrix__row--error {
  background-color: rgba(213, 41, 1, 0.2);
}

.sv-matrixdynamic__add-btn {
  background-color: #1948b3;
}

.sv-matrixdynamic__remove-btn {
  background-color: #ff1800;
}

.sv-detail-panel__icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 13px;
  width: 24px;
  transform: translate(-50%, -50%) rotate(270deg);
}

.sv-detail-panel__icon--expanded {
  transform: translate(-50%, -50%);
}

.sv-detail-panel__icon:before {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%239A9A9A;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='19,6 17,4 10,11 3,4 1,6 10,15 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 18px;
  width: 24px;
}

.sv-root-modern ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #9f9f9f;
}

.sv-root-modern ::-webkit-scrollbar-thumb {
  background: #1ab394;
}

.sv-table {
  width: 100%;
  background-color: rgba(159, 159, 159, 0.1);
  border-collapse: separate;
  border-spacing: 0;
}
.sv-table tbody tr:last-child .sv-table__cell {
  padding-bottom: 2.5em;
}
.sv-table tr:first-child .sv-table__cell {
  padding-top: 1.875em;
}
.sv-table td:first-child,
.sv-table th:first-child {
  padding-left: 1.875em;
}
.sv-table td:last-child,
.sv-table th:last-child {
  padding-right: 1.875em;
}

.sv-table__row--detail {
  background-color: #e7e7e7;
}
.sv-table__row--detail td {
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  padding: 1em 0;
}

.sv-table__cell {
  padding: 0.9375em 0;
  box-sizing: content-box;
  vertical-align: top;
}

.sv-table__cell:not(:last-child) {
  padding-right: 1em;
}

.sv-table__cell:not(:first-child) {
  padding-left: 1em;
}

.sv-table__cell--header {
  font-family: Raleway;
  font-weight: bold;
  text-align: left;
}

.sv-table__cell--rowText {
  vertical-align: middle;
}

.sv-table__cell--detail {
  text-align: center;
  vertical-align: middle;
  width: 32px;
}

.sv-table__cell--detail-rowtext {
  vertical-align: middle;
}

.sv-table__cell--detail-panel {
  padding-left: 1em;
}

.sv-table__cell--detail-button {
  appearance: none;
  position: relative;
  border: 3px solid rgba(64, 64, 64, 0.5);
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
}

.sv-table__empty--rows--section {
  text-align: center;
  vertical-align: middle;
}

.sv-table__empty--rows--text {
  padding: 20px;
}

.sv-table__cell--actions sv-action-bar,
.sv-table__cell--actions .sv-action-bar {
  margin-left: 0;
  padding-left: 0;
}

.sv-footer.sv-action-bar {
  display: block;
  min-height: 2em;
  padding: 2.5em 0 0.87em 0;
  margin-left: auto;
}
.sv-footer.sv-action-bar .sv-action__content {
  display: block;
}
.sv-footer.sv-action-bar .sv-action:not(:last-child) .sv-action__content {
  padding-right: 0;
}

.sv-btn--navigation {
  margin: 0 1em;
  float: right;
  background-color: #1ab394;
}

.sv-footer__complete-btn,
.sv-footer__next-btn,
.sv-footer__preview-btn {
  float: right;
}

.sv-footer__prev-btn,
.sv-footer__edit-btn {
  float: left;
}

[dir=rtl] .sv-footer__complete-btn,
[style*="direction:rtl"] .sv-footer__complete-btn {
  float: left;
}
[dir=rtl] .sv-footer__preview-btn,
[style*="direction:rtl"] .sv-footer__preview-btn {
  float: left;
}
[dir=rtl] .sv-footer__next-btn,
[style*="direction:rtl"] .sv-footer__next-btn {
  float: left;
}
[dir=rtl] .sv-footer__prev-btn,
[style*="direction:rtl"] .sv-footer__prev-btn {
  float: right;
}
[dir=rtl] .sv-footer__edit-btn,
[style*="direction:rtl"] .sv-footer__edit-btn {
  float: right;
}

.sv-btn.sv-action-bar-item,
.sv-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 1.214em;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 0.5em 2.786em 0.6em;
  text-align: left;
}

.sv-item {
  position: relative;
  cursor: pointer;
}

.sv-item--disabled {
  cursor: default;
}

.sv-item__decorator {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: solid 1px transparent;
  vertical-align: middle;
}

.sv-item__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.sv-item__control:focus + .sv-item__decorator {
  border-color: #1ab394;
  outline: none;
}

.sv-item__control-label {
  position: relative;
  top: 4px;
}

.sv-checkbox__decorator {
  border-radius: 2px;
}

.sv-checkbox__svg {
  border: 3px solid rgba(64, 64, 64, 0.5);
  border-radius: 2px;
  fill: transparent;
}

.sv-checkbox--allowhover:hover .sv-checkbox__svg {
  border: none;
  background-color: #9f9f9f;
  fill: white;
}

.sv-checkbox--checked .sv-checkbox__svg {
  border: none;
  background-color: #1ab394;
  fill: white;
}

.sv-checkbox--checked.sv-checkbox--disabled .sv-checkbox__svg {
  border: none;
  background-color: #dbdbdb;
  fill: white;
}

.sv-checkbox--disabled .sv-checkbox__svg {
  border: 3px solid #dbdbdb;
}

.sv-radio__decorator {
  border-radius: 100%;
}

.sv-radio__svg {
  border: 3px solid rgba(64, 64, 64, 0.5);
  border-radius: 100%;
  fill: transparent;
}

.sv-radio--allowhover:hover .sv-radio__svg {
  fill: rgba(64, 64, 64, 0.5);
}

.sv-radio--checked .sv-radio__svg {
  border-color: #404040;
  fill: #404040;
}

.sv-radio--disabled .sv-radio__svg {
  border-color: #dbdbdb;
}

.sv-radio--disabled.sv-radio--checked .sv-radio__svg {
  fill: #dbdbdb;
}

.sv-boolean {
  display: block;
  position: relative;
  line-height: 1.5em;
}

.sv-boolean__switch {
  float: left;
  box-sizing: border-box;
  width: 4em;
  height: 1.5em;
  margin-right: 1.0625em;
  margin-left: 1.3125em;
  padding: 0.125em 0.1875em;
  border-radius: 0.75em;
}

.sv-boolean input:focus ~ .sv-boolean__switch {
  outline: 1px solid #1ab394;
  outline-offset: 1px;
}

[dir=rtl] .sv-boolean__switch,
[style*="direction:rtl"] .sv-boolean__switch {
  float: right;
}

.sv-boolean__slider {
  display: block;
  width: 1.25em;
  height: 1.25em;
  transition-duration: 0.1s;
  transition-property: margin-left;
  transition-timing-function: linear;
  border: none;
  border-radius: 100%;
}

.sv-boolean--indeterminate .sv-boolean__slider {
  margin-left: calc(50% - 0.625em);
}

.sv-boolean--checked .sv-boolean__slider {
  margin-left: calc(100% - 1.25em);
}

.sv-boolean__label {
  cursor: pointer;
  float: left;
}

[dir=rtl] .sv-boolean__label,
[style*="direction:rtl"] .sv-boolean__label {
  float: right;
}

[dir=rtl] .sv-boolean--indeterminate .sv-boolean__slider,
[style*="direction:rtl"] .sv-boolean--indeterminate .sv-boolean__slider {
  margin-right: calc(50% - 0.625em);
}
[dir=rtl] .sv-boolean--checked .sv-boolean__slider,
[style*="direction:rtl"] .sv-boolean--checked .sv-boolean__slider {
  margin-right: calc(100% - 1.25em);
}

.sv-imagepicker__item {
  border: none;
  padding: 0.24em;
}

.sv-imagepicker__item--inline {
  display: inline-block;
}

.sv-imagepicker__item--inline:not(:last-child) {
  margin-right: 4%;
}

.sv-imagepicker__image {
  border: 0.24em solid transparent;
  display: block;
  pointer-events: none;
}

.sv-imagepicker__label {
  cursor: inherit;
}

.sv-imagepicker__text {
  font-size: 1.14em;
  padding-left: 0.24em;
}

.sv-imagepicker__item--allowhover:hover .sv-imagepicker__image {
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}

.sv-imagepicker__item:not(.sv-imagepicker__item--checked) .sv-imagepicker__control:focus ~ div .sv-imagepicker__image {
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}

.sv-imagepicker__item--checked .sv-imagepicker__image {
  background-color: #1ab394;
  border-color: #1ab394;
}

.sv-imagepicker__item {
  cursor: pointer;
}

.sv-imagepicker__item--disabled {
  cursor: default;
}

.sv-imagepicker__item--disabled.sv-imagepicker__item--checked .sv-imagepicker__image {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.sv-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  background: transparent;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.57em 100%;
  border: none;
  border-bottom: 0.06em solid #d4d4d4;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding: 0.25em 1.5em 0.25em 0.87em;
  height: 2.19em;
  width: 100%;
}

.sv-dropdown:focus {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");
  border-color: #d4d4d4;
  outline: none;
}

.sv-dropdown::-ms-expand {
  display: none;
}

.sv-dropdown--error {
  border-color: #d52901;
  color: #d52901;
}

.sv-dropdown--error::placeholder {
  color: #d52901;
}

.sv-dropdown option {
  color: #404040;
}

.sv-text {
  box-sizing: border-box;
  width: 100%;
  height: 2.19em;
  padding: 0.25em 0 0.25em 0.87em;
  border: none;
  border-radius: 0;
  border-bottom: 0.07em solid #d4d4d4;
  box-shadow: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1em;
}

.sv-text:focus {
  border-color: #1ab394;
  outline: none;
  box-shadow: none;
}

.sv-text:invalid {
  box-shadow: none;
}

.sv-text:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.sv-text::placeholder {
  opacity: 1;
  color: #404040;
}

.sv-text:-ms-input-placeholder {
  opacity: 1;
  color: #404040;
}

.sv-text::-ms-input-placeholder {
  opacity: 1;
  color: #404040;
}

.sv-text[type=date] {
  padding-right: 2px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.61em top 50%, 0 0;
  background-size: 0.57em auto, 100%;
}

.sv-text[type=date]:focus {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");
}

.sv-text[type=date]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: transparent;
}

.sv-text[type=date]::-webkit-clear-button {
  display: none;
}

.sv-text[type=date]::-webkit-inner-spin-button {
  display: none;
}

.sv-text--error {
  color: #d52901;
  border-color: #d52901;
}

.sv-text--error::placeholder {
  color: #d52901;
}

.sv-text--error::-ms-input-placeholder {
  color: #d52901;
}

.sv-text--error::-ms-input-placeholder {
  color: #d52901;
}

.sv-rating {
  color: #404040;
  padding-bottom: 3px;
}
.sv-rating input:focus + .sv-rating__min-text + .sv-rating__item-text,
.sv-rating input:focus + .sv-rating__item-text {
  outline: 1px solid #1ab394;
  outline-offset: 2px;
}

.sv-rating__item {
  position: relative;
  display: inline;
}

.sv-rating__item-text {
  min-width: 2.3125em;
  height: 2.3125em;
  display: inline-block;
  color: #9f9f9f;
  padding: 0 0.3125em;
  border: solid 0.1875em #9f9f9f;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.13;
  cursor: pointer;
  margin: 3px 0;
  margin-right: 0.26em;
  box-sizing: border-box;
}
.sv-rating__item-text > span {
  margin-top: 0.44em;
  display: inline-block;
}
.sv-rating__item-text:hover {
  background-color: #9f9f9f;
  color: white;
}

.sv-rating__item--selected .sv-rating__item-text {
  background-color: #1ab394;
  color: white;
  border-color: #1ab394;
}
.sv-rating__item--selected .sv-rating__item-text:hover {
  background-color: #1ab394;
}

.sv-rating__min-text {
  font-size: 1em;
  margin-right: 1.25em;
  cursor: pointer;
}

.sv-rating__max-text {
  font-size: 1em;
  margin-left: 0.87em;
  cursor: pointer;
}

.sv-question--disabled .sv-rating__item-text {
  cursor: default;
  color: #dbdbdb;
  border-color: #dbdbdb;
}
.sv-question--disabled .sv-rating__item-text:hover {
  background-color: transparent;
}
.sv-question--disabled .sv-rating__item--selected .sv-rating__item-text {
  background-color: #dbdbdb;
  color: white;
}
.sv-question--disabled .sv-rating__min-text {
  cursor: default;
}
.sv-question--disabled .sv-rating__max-text {
  cursor: default;
}

.sv-comment {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.06em solid #d4d4d4;
  border-radius: 0;
  box-sizing: border-box;
  padding: 0.25em 0.87em;
  font-family: inherit;
  font-size: 1em;
  outline: none;
  width: 100%;
}

.sv-comment:focus {
  border-color: #1ab394;
}

.sv-file {
  position: relative;
}

.sv-file__decorator {
  background-color: #f5f5f5;
  padding: 1.68em 0;
}

.sv-file__clean-btn {
  background-color: #ff1800;
  margin-top: 1.25em;
}

.sv-file__choose-btn:not(.sv-file__choose-btn--disabled) {
  background-color: #1948b3;
  display: inline-block;
}

.sv-file__choose-btn--disabled {
  cursor: default;
  background-color: #dbdbdb;
  display: inline-block;
}

.sv-file__no-file-chosen {
  display: inline-block;
  font-size: 0.87em;
  margin-left: 1em;
}

.sv-file__preview {
  display: inline-block;
  padding-right: 23px;
  position: relative;
  margin-top: 1.25em;
  vertical-align: top;
}

.sv-file__preview:not(:last-child) {
  margin-right: 31px;
}

.sv-file__remove-svg {
  position: absolute;
  fill: #ff1800;
  cursor: pointer;
  height: 16px;
  top: 0;
  right: 0;
  width: 16px;
}
.sv-file__remove-svg .sv-svg-icon {
  width: 16px;
  height: 16px;
}

.sv-file__sign a {
  color: #404040;
  text-align: left;
  text-decoration: none;
}

.sv-file__wrapper {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-left: 50%;
  transform: translate(-50%, 0);
  padding: 0;
}

.sv-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.sv-completedpage {
  font-family: Raleway;
  font-size: 1.875em;
  font-weight: bold;
  box-sizing: border-box;
  height: 14em;
  padding-top: 4.5em;
  padding-bottom: 4.5em;
  text-align: center;
  color: #404040;
  background-color: #f5f5f5;
}

.sv-completedpage:before {
  display: block;
  width: 72px;
  height: 72px;
  margin-left: calc(50% - 36px);
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 72 72' style='enable-background:new 0 0 72 72;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%239A9A9A;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M11.9,72c-0.6-0.1-1.2-0.3-1.8-0.4C4.2,70.1,0,64.7,0,58.6c0-15.1,0-30.1,0-45.2C0,6,6,0,13.4,0 c12,0,24,0,36,0c2.4,0,4.4,1.7,4.6,4c0.2,2.4-1.3,4.4-3.6,4.9C50,9,49.7,9,49.4,9C37.6,9,25.8,9,14,9c-1.5,0-2.8,0.4-3.9,1.5 c-0.8,0.9-1.2,2-1.2,3.2c0,8.2,0,16.4,0,24.6C9,45,9,51.6,9,58.2c0,2.9,1.9,4.8,4.8,4.8c14.9,0,29.7,0,44.6,0c2.6,0,4.6-2,4.6-4.6 c0-5.9,0-11.8,0-17.7c0-2.4,1.6-4.3,3.9-4.6c2.3-0.3,4.3,1,5,3.4c0,0.1,0.1,0.2,0.1,0.2c0,6.8,0,13.6,0,20.4c0,0.1-0.1,0.3-0.1,0.4 c-0.8,5.4-4.7,9.8-10.1,11.2c-0.6,0.1-1.2,0.3-1.8,0.4C44,72,28,72,11.9,72z'/%3E%3Cpath class='st0' d='M35.9,38.8c0.4-0.4,0.5-0.7,0.7-0.9c8.4-8.4,16.8-16.8,25.2-25.2c1.9-1.9,4.5-2,6.3-0.4 c1.9,1.6,2.1,4.6,0.4,6.4c-0.2,0.2-0.3,0.3-0.5,0.5c-9.5,9.5-19.1,19.1-28.6,28.6c-2.2,2.2-4.8,2.2-7,0 c-5.1-5.1-10.2-10.2-15.4-15.4c-1.3-1.3-1.7-2.8-1.2-4.5c0.5-1.7,1.6-2.8,3.4-3.1c1.6-0.4,3.1,0.1,4.2,1.3c4,4,7.9,7.9,11.9,11.9 C35.6,38.2,35.7,38.5,35.9,38.8z'/%3E%3C/g%3E%3C/svg%3E%0A");
}

@media only screen and (min-width: 1000px) {
  .sv-completedpage {
    margin-right: 5%;
    margin-left: calc(5% + 0.293em);
  }
}
@media only screen and (max-width: 1000px) {
  .sv-completedpage {
    margin-left: calc(10px + 0.293em);
    margin-right: 10px;
  }
}

.sv-header {
  white-space: nowrap;
}

.sv-logo--left {
  display: inline-block;
  vertical-align: top;
  margin-right: 2em;
}

.sv-logo--right {
  vertical-align: top;
  margin-left: 2em;
  float: right;
}

.sv-logo--top {
  display: block;
  width: 100%;
  text-align: center;
}

.sv-logo--bottom {
  display: block;
  width: 100%;
  text-align: center;
}

.sv-header__text {
  display: inline-block;
  vertical-align: top;
}

.sv-list {
  padding: 0;
  margin: 0;
  background: var(--background, #fff);
  font-family: "Open Sans";
  list-style-type: none;
}

.sv-list__item {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: calc(1 * var(--base-unit, 8px)) calc(2 * var(--base-unit, 8px));
  color: var(--foreground, #161616);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sv-list__item:hover, .sv-list__item:focus {
  background-color: var(--background-dim, #f3f3f3);
  outline: none;
}

.sv-list__item-icon {
  float: left;
  width: calc(3 * var(--base-unit, 8px));
  height: calc(3 * var(--base-unit, 8px));
  margin-right: calc(2 * var(--base-unit, 8px));
}
.sv-list__item-icon svg {
  display: block;
}
.sv-list__item-icon use {
  fill: var(--foreground-light, #909090);
}

.sv-list__item--selected {
  background-color: var(--primary, #19b394);
  color: var(--background, #fff);
}
.sv-list__item--selected:hover, .sv-list__item--selected:focus {
  background-color: var(--primary, #19b394);
  color: var(--background, #fff);
}
.sv-list__item--selected .sv-list__item-icon use {
  fill: var(--background, #fff);
}

.sv-list__item--disabled {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.16));
  cursor: default;
  pointer-events: none;
}

.sv-list__item span {
  white-space: nowrap;
}

.sv-list__container {
  position: relative;
}

.sv-list__filter {
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--border-inside, rgba(0, 0, 0, 0.16));
  background: var(--background, #fff);
}

.sv-list__filter-icon {
  display: block;
  position: absolute;
  top: calc(2 * var(--base-unit, 8px));
  left: calc(2 * var(--base-unit, 8px));
}
.sv-list__filter-icon .sv-svg-icon {
  width: calc(3 * var(--base-unit, 8px));
  height: calc(3 * var(--base-unit, 8px));
}
.sv-list__filter-icon .sv-svg-icon use {
  fill: var(--foreground-light, #909090);
}

.sv-list__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background: var(--background, #fff);
  box-sizing: border-box;
  width: 100%;
  border: 1px solid var(--border-inside, rgba(0, 0, 0, 0.16));
  outline: none;
  font-size: 1em;
  color: var(--foreground, #161616);
  padding: calc(2 * var(--base-unit, 8px));
  padding-left: calc(8 * var(--base-unit, 8px));
  padding-right: calc(3 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
}

.sv-list__input::placeholder {
  color: var(--foreground-light, #909090);
}

.sv-list__input:focus {
  border: 1px solid var(--primary, #19b394);
}

.sv-list__input:disabled,
.sv-list__input:disabled::placeholder {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.16));
}

.sv-button-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: calc(2 * var(--base-unit, 8px));
  height: calc(6 * var(--base-unit, 8px));
  overflow: auto;
}

.sv-button-group__item {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 100%;
  padding: 11px calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  border: 1px solid var(--border, #d6d6d6);
  outline: none;
  font-size: calc(2 * var(--base-unit, 8px));
  font-weight: 400;
  background: var(--background, #fff);
  cursor: pointer;
  overflow: hidden;
  color: var(--foreground, #161616);
  position: relative;
}
.sv-button-group__item:not(:first-of-type) {
  margin-left: -1px;
}

.sv-button-group__item--hover:hover {
  background-color: var(--background-dim, #f3f3f3);
}

.sv-button-group__item-icon {
  display: block;
  height: calc(3 * var(--base-unit, 8px));
}
.sv-button-group__item-icon use {
  fill: var(--foreground-light, #909090);
}

.sv-button-group__item--selected {
  font-weight: 600;
  color: var(--primary, #19b394);
}
.sv-button-group__item--selected .sv-button-group__item-icon use {
  fill: var(--primary, #19b394);
}
.sv-button-group__item--selected:hover {
  background-color: var(--background, #fff);
}

.sv-button-group__item-decorator {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.sv-button-group__item-caption {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-button-group__item-icon + .sv-button-group__item-caption {
  margin-left: calc(1 * var(--base-unit, 8px));
}

.sv-button-group__item--disabled {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.16));
  cursor: default;
}
.sv-button-group__item--disabled .sv-button-group__item-icon use {
  fill: var(--foreground-disabled, rgba(22, 22, 22, 0.16));
}
.sv-button-group__item--disabled:hover {
  background-color: var(--background, #fff);
}

.sv-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.sv-hidden {
  display: none !important;
}

.sjs_sp_container {
  position: relative;
}

.sjs_sp_controls {
  position: absolute;
  left: 0;
  bottom: 0;
}

.sjs_sp_controls > button {
  user-select: none;
}

.sjs_sp_container > div > canvas:focus {
  outline: none;
}

.sjs_sp_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.sv_window {
  position: fixed;
  bottom: 3px;
  right: 10px;
  background-color: cadetblue;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
  z-index: 100;
}

.sv_window_title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.sv_window_content {
  padding: 8px;
  margin: 0;
  max-height: 80vh;
  overflow-y: auto;
}

.sv_window_title a,
.sv_window_title a:link,
.sv_window_title a:visited {
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  color: black;
}

.sv-boolean__decorator {
  border-radius: 2px;
}

.sv_main .sv-boolean__decorator + .sv-boolean__label {
  float: none;
  vertical-align: top;
  margin-left: 0.5em;
}

.sv-boolean__svg {
  border: none;
  border-radius: 2px;
  background-color: #1ab394;
  fill: white;
  width: 24px;
  height: 24px;
}

.sv-boolean--allowhover:hover .sv-boolean__checked-path {
  display: inline-block;
}
.sv-boolean--allowhover:hover .sv-boolean__svg {
  background-color: #9f9f9f;
  fill: white;
}
.sv-boolean--allowhover:hover .sv-boolean__unchecked-path,
.sv-boolean--allowhover:hover .sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean__checked-path,
.sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #1ab394;
}
.sv-boolean--indeterminate .sv-boolean__indeterminate-path {
  display: inline-block;
}

.sv-boolean--indeterminate .sv-boolean__unchecked-path,
.sv-boolean--checked .sv-boolean__unchecked-path {
  display: none;
}

.sv-boolean--checked .sv-boolean__checked-path {
  display: inline-block;
}

.sv-boolean--disabled.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #dbdbdb;
}

.sv-boolean--disabled .sv-boolean__svg {
  background-color: #dbdbdb;
}

td.sv_matrix_cell .sv_qbln,
td.td.sv_matrix_cell .sv_qbln {
  text-align: center;
}
td.sv_matrix_cell .sv_qbln .sv-boolean,
td.td.sv_matrix_cell .sv_qbln .sv-boolean {
  text-align: initial;
}

.sv-action-bar {
  display: flex;
  box-sizing: content-box;
  position: relative;
  align-items: center;
  margin-left: auto;
  padding: 0 calc(2 * var(--base-unit, 8px));
  overflow: hidden;
  white-space: nowrap;
}

.sv-action--first {
  margin-right: auto;
  flex: 1;
}
.sv-action--first .sv-action-bar-item {
  flex-direction: row-reverse;
}
.sv-action--first .sv-action-bar-item__title {
  margin-left: 0;
  margin-right: calc(1 * var(--base-unit, 8px));
}

.sv-action-bar-separator {
  display: inline-block;
  width: 1px;
  height: 24px;
  vertical-align: middle;
  margin-right: 16px;
  background-color: var(--border, #d6d6d6);
}

.sv-action-bar-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: calc(5 * var(--base-unit, 8px));
  padding: calc(1 * var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  line-height: calc(3 * var(--base-unit, 8px));
  font-size: calc(2 * var(--base-unit, 8px));
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  white-space: nowrap;
}

button.sv-action-bar-item {
  overflow: hidden;
}

.sv-action-bar-item__icon svg {
  display: block;
}
.sv-action-bar-item__icon use {
  fill: var(--foreground-light, #909090);
}

.sv-action-bar-item:hover,
.sv-action-bar-item:focus-visible {
  outline: none;
  background-color: var(--background-dim, #f3f3f3);
}

.sv-action-bar-item:active {
  opacity: 0.5;
}

.sv-action-bar-item:disabled {
  opacity: 0.25;
  cursor: default;
  color: var(--foreground, #161616);
}
.sv-action-bar-item:disabled .sv-action-bar-item__icon use {
  fill: var(--foreground, #161616);
}

.sv-action-bar-item__title {
  vertical-align: middle;
  white-space: nowrap;
}

.sv-action-bar-item--secondary .sv-action-bar-item__icon use {
  fill: var(--secondary, #ff9814);
}

.sv-action-bar-item--active .sv-action-bar-item__icon use {
  fill: var(--primary, #19b394);
}

.sv-action-bar-item-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: calc(5 * var(--base-unit, 8px));
  padding: calc(1 * var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  line-height: calc(3 * var(--base-unit, 8px));
  font-size: calc(2 * var(--base-unit, 8px));
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv-action-bar-item__title--with-icon {
  margin-left: calc(1 * var(--base-unit, 8px));
}

.sv-action--last {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.sv-expand-action:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 10px;
  width: 12px;
  margin: auto 8px;
}

.sv-expand-action--expanded:before {
  transform: rotate(180deg);
}

.sv-dots {
  width: 48px;
}

.sv-dots__item {
  width: 100%;
}
.sv-dots__item .sv-action-bar-item__icon {
  margin: auto;
}

.sv-action--hidden {
  width: 0px;
  height: 0px;
  overflow: hidden;
  visibility: hidden;
}

.sv-action__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sv-action__content > * {
  flex: 0 0 auto;
}

.sv-action:not(:last-child) > .sv-action__content {
  box-sizing: content-box;
  padding-right: calc(2 * var(--base-unit, 8px));
}

.sv-action--space {
  margin-left: auto;
}

.sv-action-bar-item--pressed {
  background-color: var(--background-dim, #f3f3f3);
  opacity: 50%;
}

.sv-title-actions {
  display: flex;
  align-items: center;
  width: 100%;
}

.sv-title-actions__title {
  flex-wrap: wrap;
  max-width: 90%;
  min-width: 50%;
  white-space: initial;
}

.sv-title-actions__bar {
  min-width: 56px;
}

.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question {
  display: block;
  width: 100% !important;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-question__header--location--left,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-question__header--location--left {
  float: none;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-selectbase__item--inline,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-imagepicker__item--inline,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-selectbase__item--inline,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-imagepicker__item--inline {
  display: block;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table {
  display: block;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table thead,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table thead {
  display: none;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td.sv-table__cell--choice,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td.sv-table__cell--choice {
  text-align: initial;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tbody,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tr,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tbody,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tr,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td {
  display: block;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdropdown td:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdynamic td:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdropdown td:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdynamic td:before {
  content: attr(data-responsive-title);
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root td label.sv-matrix__label,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root td label.sv-matrix__label {
  display: inline;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root td:after,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root td:after {
  content: attr(data-responsive-title);
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root .sv-matrix__cell,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root .sv-matrix__cell {
  text-align: initial;
}

@media (max-width: 600px) {
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question {
    display: block;
    width: 100% !important;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-question__header--location--left,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-question__header--location--left {
    float: none;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-selectbase__item--inline,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-imagepicker__item--inline,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-selectbase__item--inline,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-imagepicker__item--inline {
    display: block;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table {
    display: block;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table thead,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table thead {
    display: none;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td.sv-table__cell--choice,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td.sv-table__cell--choice {
    text-align: initial;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tbody,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tr,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tbody,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tr,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td {
    display: block;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdropdown td:before,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdynamic td:before,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdropdown td:before,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdynamic td:before {
    content: attr(data-responsive-title);
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root td label.sv-matrix__label,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root td label.sv-matrix__label {
    display: inline;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root td:after,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root td:after {
    content: attr(data-responsive-title);
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root .sv-matrix__cell,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root .sv-matrix__cell {
    text-align: initial;
  }
}
body {
  --sv-modern-mark: true;
}